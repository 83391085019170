// Perlin noise implementation
function createNoise() {
    const permutation = new Array(256).fill(0).map((_, i) => i);
    for (let i = 0; i < 256; i++) {
        const j = Math.floor(Math.random() * (i + 1));
        [permutation[i], permutation[j]] = [permutation[j], permutation[i]];
    }
    return (x, y = 0) => {
        x = x % 256;
        y = y % 256;
        const X = Math.floor(x) & 255;
        const Y = Math.floor(y) & 255;
        x -= Math.floor(x);
        y -= Math.floor(y);
        const u = x * x * x * (x * (x * 6 - 15) + 10);
        const v = y * y * y * (y * (y * 6 - 15) + 10);
        const A = permutation[X] + Y;
        const B = permutation[X + 1] + Y;
        return lerp(
            lerp(permutation[A], permutation[B], u),
            lerp(permutation[A + 1], permutation[B + 1], u),
            v
        ) / 256;
    };
}

function lerp(start, end, amt) {
    return (1-amt)*start + amt*end;
}

export function noiseFlow() {
    return {
        canvas: null,
        ctx: null,
        width: 400,
        animationFrame: null,
        time: 0,
        step: 3,
        noise: null,

        init() {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext('2d');
            this.noise = createNoise();
            
            // Set initial canvas size
            this.canvas.width = this.width;
            this.canvas.height = this.width;
            
            // Start animation
            this.draw();

            // Handle cleanup on page leave
            window.addEventListener('beforeunload', () => this.cleanup());
        },

        cleanup() {
            if (this.animationFrame) {
                cancelAnimationFrame(this.animationFrame);
            }
        },

        // Processing's original function converted
        calculatePoint(x, y) {
            const e = this.width/2 - y;
            const d = this.noise(this.time + x/190)/8 + 
                     this.noise(x/30, y/40 - this.time*3)/8 + 
                     e*2e-3;
            return [
                x,
                y/(6 + d*9 + 2*Math.sin(this.time + e/49)) - d*90 + 100
            ];
        },

        draw() {
            // Clear with dark background
            this.ctx.fillStyle = 'rgb(6, 6, 6)';
            this.ctx.fillRect(0, 0, this.width, this.width);

            this.time += 0.01;

            // Draw noise flow
            for(let y = 0; y < this.width; y += this.step) {
                for(let x = 0; x < this.width; x += this.step) {
                    const p1 = this.calculatePoint(x, y);
                    const p2 = this.calculatePoint(x, y);
                    const p3 = this.calculatePoint(x + this.step, y + this.step);

                    this.ctx.beginPath();
                    this.ctx.moveTo(p1[0], p1[1]);
                    this.ctx.lineTo(p2[0], p2[1]);
                    this.ctx.lineTo(p3[0], p3[1]);
                    this.ctx.closePath();

                    this.ctx.strokeStyle = `rgb(${this.width}, ${y/3}, ${y/3})`;
                    this.ctx.stroke();
                }
            }
            
            this.animationFrame = requestAnimationFrame(() => this.draw());
        }
    };
}
