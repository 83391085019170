import '@google/model-viewer';
import './alpine.js';  // Import Alpine initialization first

// Conditionally load Jupiter Terminal only when needed
document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('[data-jupiter-terminal]')) {
        import('./jupiterTerminal.js').then(module => {
            // Make functions globally available
            window.initializeJupiterTerminal = module.initializeJupiterTerminal;
            window.closeJupiterTerminal = module.closeJupiterTerminal;
            window.resumeJupiterTerminal = module.resumeJupiterTerminal;
            
            // Initialize with the default target ID
            module.initializeJupiterTerminal('integrated-terminal');
        });
    }
});
import menu from './menu.js';
import './starPage.js';  // Star generator page
// import moonshotProgress from './moonshot.js';
import { initializeActivatePage } from './activate.js';
import { bunnyPage } from './bunnyPage.js';
import { seaSirensPage } from './seaSirensPage.js';
// import { connectPhantomWallet } from './payment';
import { noiseFlow } from './noiseFlow.js';
import { statePageCoins } from './statePage.js';
import swapPage from './swapPage.js';
import { initializePageTransitions } from './pageTransitions.js';
import { initializeCountdown } from './countdown.js';
import { honeyPage } from './honeyPage.js';
import { beePage } from './beePage.js';
import { podPage } from './podPage.js';
import { treeDecPage } from './treeDecPage.ts';
import superbowlPage from './superbowlPage.js';

// Make wallet connection available globally
// window.connectPhantomWallet = connectPhantomWallet;

// Initialize page transitions
initializePageTransitions();

// Initialize countdown if we're on the home page
if (document.getElementById('countdown')) {
    initializeCountdown();
}

// Initialize components
document.addEventListener('alpine:init', () => {
  Alpine.data('bunnyPage', bunnyPage);
  Alpine.data('seaSirensPage', seaSirensPage);
  Alpine.data('statePageCoins', statePageCoins);
  Alpine.data('noiseFlow', noiseFlow);
  Alpine.data('swapPage', swapPage);
  Alpine.data('honeyPage', honeyPage);
  Alpine.data('beePage', beePage);
  Alpine.data('podPage', podPage);
  Alpine.data('treeDecPage', treeDecPage);
  // Alpine.data('moonshotProgress', moonshotProgress);
  Alpine.data('superbowlPage', superbowlPage);
});

// Initialize buoy data component
Alpine.data('buoyData', () => ({
    waveHeight: 'Loading...',
    waterTemp: 'Loading...',
    lastUpdate: '',
    isLoading: true,
    error: null,

    init() {
        this.fetchData();
        // Update every 5 minutes
        setInterval(() => this.fetchData(), 5 * 60 * 1000);
    },

    async fetchData() {
        try {
            this.isLoading = true;
            this.error = null;
            
            const response = await fetch('/api/buoy-data');
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch buoy data');
            }

            if (data.error) {
                throw new Error(data.error);
            }

            // Validate the data
            if (!data.waveHeight || !data.waterTemp) {
                throw new Error('Missing buoy measurements');
            }

            // Convert string values to numbers and format
            const waveHeight = parseFloat(data.waveHeight);
            const waterTemp = parseFloat(data.waterTemp);

            if (isNaN(waveHeight) || isNaN(waterTemp)) {
                throw new Error('Invalid buoy measurements');
            }

            this.waveHeight = `${waveHeight.toFixed(1)}ft`;
            this.waterTemp = `${waterTemp.toFixed(1)}°F`;
            
            // Format timestamp
            const timestamp = new Date(data.timestamp);
            this.lastUpdate = `Last updated: ${timestamp.toLocaleTimeString()}`;
            
            // Clear any previous errors
            this.error = null;
        } catch (error) {
            console.error('Error fetching buoy data:', error);
            this.error = error.message || 'Failed to fetch buoy data';
            this.waveHeight = 'Error';
            this.waterTemp = 'Error';
        } finally {
            this.isLoading = false;
        }
    }
}));

// Initialize activate page if we're on that page
document.addEventListener('DOMContentLoaded', () => {
    // Check if we're on the activate page by looking for its form
    if (document.getElementById('realityTagForm')) {
        initializeActivatePage();
    }

    // Initialize bee model viewer if present
    const beeModel = document.querySelector('#bee-model');
    if (beeModel) {
        beeModel.addEventListener('load', () => {
            // Any bee model specific initialization can go here
            console.log('Bee model loaded successfully');
        });
    }

    // Existing model-viewer code
    const modelViewer = document.querySelector('#jo-model');
    if (modelViewer) {
        modelViewer.addEventListener('load', async () => {
            const material = modelViewer.model.materials[0];
            if (material && material.pbrMetallicRoughness.baseColorTexture) {
                // Set initial texture
                const initialImage = '/assets/images/jo/dosomething.jpg';
                try {
                    const texture = await modelViewer.createTexture(initialImage);
                    material.pbrMetallicRoughness.baseColorTexture.texture = texture;
                } catch (error) {
                    console.error('Error loading texture:', error);
                }
            }
        });
    }
});

// Function to update model texture
window.updateModelTexture = async (modelViewer, imagePath) => {
    if (modelViewer && modelViewer.model) {
        const material = modelViewer.model.materials[0];
        if (material && material.pbrMetallicRoughness.baseColorTexture) {
            try {
                const texture = await modelViewer.createTexture(imagePath);
                material.pbrMetallicRoughness.baseColorTexture.texture = texture;
            } catch (error) {
                console.error('Error updating texture:', error);
            }
        }
    }
};
