import { realityCoins } from './realityCoins';

// Buoy data component
export function buoyData() {
    return {
        waveHeight: 'Loading...',
        waterTemp: 'Loading...',
        lastUpdate: '',
        error: null,
        
        async init() {
            try {
                const response = await fetch('/api/buoy-data');
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const data = await response.json();
                
                this.waveHeight = `${data.waveHeight} ft`;
                this.waterTemp = `${data.waterTemp}°F`;
                this.lastUpdate = new Date(data.timestamp).toLocaleTimeString();
                this.error = null;

                // Update every 5 minutes
                setInterval(() => this.init(), 5 * 60 * 1000);
            } catch (err) {
                console.error('Error fetching buoy data:', err);
                this.error = 'Failed to load buoy data';
            }
        }
    };
}

// Reality coins component with extended functionality
export function statePageCoins() {
    const manager = realityCoins();
    
    return {
        sortedCoins: manager.sortedCoins,
        formatPrice: (price) => manager.formatPrice(price),
        formatChange: (change) => manager.formatChange(change),
        formatVolume: (volume) => manager.formatVolume(volume),
        
        // Additional state-specific data
        totalValueStaked: '$420.69K',
        activeStakers: '1,337',
        topLocations: [
            { name: 'Ft Pierce Beach', value: '$123.4K' },
            { name: 'Cocoa Beach', value: '$89.7K' },
            { name: 'Miami Beach', value: '$67.8K' }
        ],
        recentActivity: [
            {
                type: '🏊‍♂️',
                action: 'New Stake',
                details: '1000 OHRT at Ft Pierce Beach',
                timeAgo: '2 mins ago'
            },
            {
                type: '🎯',
                action: 'Location Verified',
                details: 'PPP Proof Submitted at Miami Beach',
                timeAgo: '5 mins ago'
            },
            {
                type: '💰',
                action: 'Rewards Claimed',
                details: '42.69 DEEP from Cocoa Beach',
                timeAgo: '15 mins ago'
            }
        ],
        
        async init() {
            // In the future, we can add real-time updates here
            setInterval(() => {
                // Simulate price updates
                this.sortedCoins = this.sortedCoins.map(coin => ({
                    ...coin,
                    price: coin.price * (1 + (Math.random() - 0.5) * 0.01),
                    change24h: coin.change24h + (Math.random() - 0.5)
                }));
            }, 30 * 1000); // Update every 30 seconds
        }
    };
}
