import Alpine from 'alpinejs'
import 'htmx.org';
import { realityCoinStore } from './realityCoinStore';
import { realityTokenStore } from './realityTokenStore';
import { realityCoins } from './realityCoins';
import moonPage from './moonPage';
import { treePage } from './treePage';

// Add to your main.js or create wavelens.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Water } from 'three/examples/jsm/objects/Water';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { joPage } from './joPage';
import { eggPage } from './eggPage';

window.Alpine = Alpine

// Create a container for Three.js instances outside of Alpine's reactivity system
const threeInstances = {
  scene: null,
  camera: null,
  renderer: null,
  water: null,
  sun: null,
  sky: null,
  controls: null
};

// Start Alpine when the page is ready.
window.addEventListener('DOMContentLoaded', () => {
  Alpine.start()
});

// Initialize Alpine stores and global functions
window.addEventListener('alpine:initializing', () => {
  // Initialize translations store with default state
  Alpine.store('translations', {
    currentLang: localStorage.getItem('lang') || 'en',
    translations: {},
    t(key) {
      const keys = key.split('.');
      let value = this.translations[this.currentLang];
      for (const k of keys) {
        value = value?.[k];
      }
      return value || key;
    },
    async init() {
      try {
        const response = await fetch('/translations.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const translations = await response.json();
        
        // Validate translations structure
        if (!translations || typeof translations !== 'object') {
          throw new Error('Invalid translations format');
        }
        
        // Ensure default English translations exist
        if (!translations.en) {
          console.warn('Missing English translations, using fallback');
          translations.en = {
            nav: {
              home: 'Home',
              reality: 'Reality',
              games: 'Games',
              more: 'More',
              about: 'About',
              dashboard: 'Dashboard',
              getMoney: 'Get Money'
            },
            reality: {
              overview: 'Overview',
              tokens: 'Reality Tokens',
              coins: 'Reality Coins',
              tags: 'Reality Tags',
              bonds: 'Bonds',
              allBonds: 'All Bonds',
              loveBurn: 'Love Burn Bonds'
            }
          };
        }
        
        this.translations = translations;
        console.log('Translations loaded successfully:', translations);
      } catch (error) {
        console.error('Failed to load translations:', error);
        // Fallback to English translations
        this.translations = {
          en: {
            nav: {
              home: 'Home',
              reality: 'Reality',
              games: 'Games',
              more: 'More',
              about: 'About',
              dashboard: 'Dashboard',
              getMoney: 'Get Money'
            },
            reality: {
              overview: 'Overview',
              tokens: 'Reality Tokens',
              coins: 'Reality Coins',
              tags: 'Reality Tags',
              bonds: 'Bonds',
              allBonds: 'All Bonds',
              loveBurn: 'Love Burn Bonds'
            }
          }
        };
      }
    }
  });

  // Initialize translations
  Alpine.store('translations').init();

  Alpine.data('moonPage', moonPage);
  Alpine.data('joPage', joPage);
  Alpine.data('eggPage', eggPage);
  Alpine.data('treePage', treePage);
  
  // Add our new realityCoins component
  Alpine.data('realityCoins', realityCoins);

  // Navigation store (existing)
  Alpine.store('nav', {
    isOpen: false,
    close() { return this.isOpen = false },
    open() { return this.isOpen = true },
    toggle() { return this.isOpen = !this.isOpen }
  });

  // Enhanced Wallet store with better mobile support and error handling
  Alpine.store('wallet', {
    address: '',
    isConnected: false,
    connectionError: '',
    isConnecting: false,
    
    async connect(event) {
      if (event) event.preventDefault();
      if (this.isConnecting) return false;
      this.isConnecting = true;
      this.connectionError = '';

      try {
        // Check if we're on mobile
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        
        // If on mobile and Phantom isn't injected, we're probably in a deeplink
        if (isMobile && !window.solana?.isPhantom) {
          window.location.href = 'https://phantom.app/ul/browse/https://currentseas.pages.dev';
          return true;
        }

        // For desktop or mobile with Phantom injected
        if (!window.solana) {
          if (isMobile) {
            window.location.href = 'https://phantom.app/ul/browse/https://currentseas.pages.dev';
            return true;
          }
          throw new Error('Please install Phantom wallet');
        }

        const provider = window.solana;
        
        if (!provider.isPhantom) {
          throw new Error('Please install Phantom wallet');
        }

        // Check if already connected
        if (provider.isConnected && provider.publicKey) {
          this.address = provider.publicKey.toString();
          this.isConnected = true;
          return true;
        }

        // Attempt connection with retry logic
        let attempts = 0;
        const maxAttempts = 3;
        
        while (attempts < maxAttempts) {
          try {
            // Wait a bit between retries
            if (attempts > 0) {
              await new Promise(resolve => setTimeout(resolve, 1000));
            }

            const response = await provider.connect();
            const { publicKey } = response;
            
            if (!publicKey) {
              throw new Error('No public key received');
            }

            this.address = publicKey.toString();
            this.isConnected = true;

            // Check reality coins balance after connecting
            await Alpine.store('realityCoins').checkBalance();

            // Save connection state
            localStorage.setItem('wallet_autoconnect', 'true');

            // Close the wallet modal if it's open
            const walletOptionsElement = document.querySelector('[x-data]');
            if (walletOptionsElement && walletOptionsElement.__x) {
              walletOptionsElement.__x.$data.showWalletOptions = false;
            }

            // Trigger custom event for UI updates
            window.dispatchEvent(new CustomEvent('wallet-connected', {
              detail: { address: this.address }
            }));

            return true;
          } catch (error) {
            attempts++;
            console.warn(`Connection attempt ${attempts} failed:`, error);
            
            // If it's the last attempt, throw the error
            if (attempts === maxAttempts) {
              throw error;
            }
          }
        }

        throw new Error('Failed to connect after multiple attempts');
      } catch (error) {
        console.error('Wallet connection error:', error);
        this.connectionError = error.message || 'Failed to connect to wallet';
        this.disconnect();
        return false;
      } finally {
        this.isConnecting = false;
      }
    },

    disconnect() {
      try {
        if (window.solana?.disconnect) {
          window.solana.disconnect();
        }
      } catch (error) {
        console.error('Error disconnecting:', error);
      }
      
      this.address = '';
      this.isConnected = false;
      this.connectionError = '';
      
      // Clear auto-connect preference
      localStorage.removeItem('wallet_autoconnect');
      
      // Trigger custom event for UI updates
      window.dispatchEvent(new CustomEvent('wallet-disconnected'));
    },

    // Try to auto-connect if previously connected
    async tryAutoConnect() {
      if (localStorage.getItem('wallet_autoconnect') === 'true' && window.solana?.isPhantom) {
        try {
          await this.connect();
        } catch (error) {
          console.error('Auto-connect failed:', error);
          // Clear auto-connect on failure
          localStorage.removeItem('wallet_autoconnect');
        }
      }
    }
  });

  // Reality Coin store
  Alpine.store('realityCoins', realityCoinStore);

  // Reality Token store
  Alpine.store('realityToken', realityTokenStore);

  // Leaderboard store
  Alpine.store('leaderboard', {
    players: [
      {
        address: '0x1234567890abcdef',
        score: 1500,
        favoriteGame: 'CSEAS Spin'
      },
      {
        address: '0xabcdef1234567890',
        score: 1200,
        favoriteGame: 'WaveLens'
      },
      {
        address: '0x9876543210fedcba',
        score: 1000,
        favoriteGame: 'Bubble Bobber'
      }
    ]
  });

  // Tap store for NFC functionality
  Alpine.store('tap', {
    nfcStatus: 'idle',
    lastScannedUrl: null
  });

  // Model generation store
  Alpine.store('modelGen', {
    isLoading: false,
    currentModelUrl: '',
    error: '',

    showError(message) {
      // Display error in toast
      const toastContainer = document.getElementById('toast-container');
      if (toastContainer) {
        toastContainer.innerHTML = `<div class='alert alert-error'><span>${message}</span></div>`;
        setTimeout(() => {
          if (toastContainer.innerHTML.includes(message)) {
            toastContainer.innerHTML = '';
          }
        }, 5000);
      }
    },

    async generateFromText(prompt, options = {}) {
      if (!Alpine.store('wallet').isConnected) {
        this.showError('Please connect your wallet first');
        throw new Error('Please connect your wallet first');
      }

      this.isLoading = true;
      this.error = '';

      try {
        console.log('Sending request with prompt:', prompt);
        
        const response = await fetch('/api/generate-model', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt,
            walletAddress: Alpine.store('wallet').address,
            ...options
          })
        });

        const data = await response.json();
        console.log('API Response:', data);

        if (!response.ok) {
          throw new Error(data.error || data.message || 'Failed to generate model');
        }

        this.currentModelUrl = data.modelUrl;
        
        // Award reality coins for successful generation
        await Alpine.store('realityCoins').earnCoins(10, 'Generated 3D model from text');
        
        return data.modelUrl;
      } catch (error) {
        console.error('Generation error:', error);
        this.error = error.message;
        this.showError(error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async generateFromImage(imageFile, options = {}) {
      if (!Alpine.store('wallet').isConnected) {
        this.showError('Please connect your wallet first');
        throw new Error('Please connect your wallet first');
      }

      this.isLoading = true;
      this.error = '';

      try {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('walletAddress', Alpine.store('wallet').address);
        formData.append('options', JSON.stringify(options));

        const response = await fetch('/api/generate-model-from-image', {
          method: 'POST',
          body: formData
        });

        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || data.message || 'Failed to generate model from image');
        }

        this.currentModelUrl = data.modelUrl;
        
        // Award reality coins for successful generation
        await Alpine.store('realityCoins').earnCoins(15, 'Generated 3D model from image');
        
        return data.modelUrl;
      } catch (error) {
        console.error('Image generation error:', error);
        this.error = error.message;
        this.showError(error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  });

  // Global Alpine functions
  Alpine.data('modelGenerator', () => ({
    activeTab: 'text',
    
    init() {
      // Try to auto-connect on page load
      Alpine.store('wallet').tryAutoConnect();

      // Listen for reality coins updates
      window.addEventListener('reality-coins-updated', (event) => {
        console.log('Reality coins balance updated:', event.detail.balance);
      });
    }
  }));

  // NFC handling functions
  window.startNfcScan = async function() {
    if ('NDEFReader' in window) {
      try {
        const ndef = new NDEFReader();
        Alpine.store('tap').nfcStatus = 'scanning';
        
        await ndef.scan();
        
        ndef.addEventListener("reading", ({ message, serialNumber }) => {
          for (const record of message.records) {
            if (record.recordType === "url") {
              const decoder = new TextDecoder();
              const url = decoder.decode(record.data);
              if (url.includes('cseas.fun/t/')) {
                Alpine.store('tap').lastScannedUrl = url;
              }
            }
          }
        });

      } catch (error) {
        console.error(error);
        Alpine.store('tap').nfcStatus = 'error';
      }
    } else {
      Alpine.store('tap').nfcStatus = 'error';
    }
  }

  window.stopNfcScan = function() {
    Alpine.store('tap').nfcStatus = 'idle';
  }
});

// Handle return from Phantom deeplink
window.addEventListener('load', () => {
  // Check if we're returning from a Phantom deeplink
  const url = new URL(window.location.href);
  if (url.searchParams.has('phantom_encryption_public_key')) {
    // Try to connect
    Alpine.store('wallet').connect();
  }
});

// Register custom elements if needed
if ('customElements' in window) {
  // Add any custom element definitions here
  // Example: customElements.define('model-viewer', ModelViewer);
}

// Add to your main.js or create a new bubbleBobber.js file
Alpine.data('bubbleBobber', () => ({
  isPlaying: false,
  score: 0,
  oxygen: 100,
  showStory: false,
  selectedSeavatar: null,
  gameLoop: null,
  canvas: null,
  ctx: null,
  pendant: {
      x: 0,
      y: 0,
      radius: 30,
      rotation: 0
  },
  bubbles: [],
  obstacles: [],
  seavatars: [
      { id: 1, name: 'Turtle', image: '/assets/images/turtle-avatar.png' },
      { id: 2, name: 'Dolphin', image: '/assets/images/dolphin-avatar.png' },
      { id: 3, name: 'Seahorse', image: '/assets/images/seahorse-avatar.png' }
  ],

  init() {
      this.canvas = this.$refs.canvas;
      this.ctx = this.canvas.getContext('2d');
      this.resizeCanvas();
      window.addEventListener('resize', () => this.resizeCanvas());
      this.setupControllers();
  },

  resizeCanvas() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.pendant.x = this.canvas.width / 2;
      this.pendant.y = this.canvas.height / 2;
  },

  startGame() {
      if (!this.selectedSeavatar) {
          this.$store.toast.error('Please select a Seavatar first!');
          return;
      }
      
      this.isPlaying = true;
      this.score = 0;
      this.oxygen = 100;
      this.bubbles = [];
      this.obstacles = [];
      this.gameLoop = requestAnimationFrame(() => this.update());
  },

  async endGame() {
      this.isPlaying = false;
      cancelAnimationFrame(this.gameLoop);
      
      // Award CSEAS tokens based on score
      if (this.$store.wallet.isConnected) {
          try {
              await this.$store.realityCoins.earnCoins(
                  Math.floor(this.score / 10),
                  'Bubble Bobber game rewards'
              );
          } catch (error) {
              console.error('Failed to award CSEAS:', error);
          }
      }
  },

  update() {
      if (!this.isPlaying) return;

      // Clear canvas
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // Update oxygen
      this.oxygen = Math.max(0, this.oxygen - 0.1);
      if (this.oxygen <= 0) {
          this.endGame();
          return;
      }

      // Update game elements
      this.updateBubbles();
      this.updateObstacles();
      this.checkCollisions();
      this.draw();

      this.gameLoop = requestAnimationFrame(() => this.update());
  },

  // Add remaining game logic methods here...
  selectSeavatar(id) {
      this.selectedSeavatar = id;
  },

  setupControllers() {
      // Mouse/touch controls
      const isMobile = 'ontouchstart' in window;
      
      if (isMobile) {
          window.addEventListener('deviceorientation', (e) => {
              if (!this.isPlaying) return;
              // Implement tilt controls
          });
      } else {
          this.canvas.addEventListener('mousemove', (e) => {
              if (!this.isPlaying) return;
              const rect = this.canvas.getBoundingClientRect();
              this.pendant.x = e.clientX - rect.left;
              this.pendant.y = e.clientY - rect.top;
          });
      }
  }
}));

Alpine.data('waveLens', () => ({
  showMapModal: false,
    showHelpModal: false,
    locationInfo: 'Click on the map to select a location',
    animationFrameId: null,

    init() {
        // Wait for the next tick to ensure DOM is ready
        this.$nextTick(() => {
            this.initThreeJS();
            this.animate();
            this.setupEventListeners();
        });

        // Show help modal on first visit
        if (!localStorage.getItem('wavelensFirstVisit')) {
            this.showHelpModal = true;
            localStorage.setItem('wavelensFirstVisit', 'true');
        }

        // Cleanup on component destroy
        this.$cleanup = () => {
            if (this.animationFrameId) {
                cancelAnimationFrame(this.animationFrameId);
            }
            if (threeInstances.controls) {
                threeInstances.controls.dispose();
            }
            if (threeInstances.renderer) {
                threeInstances.renderer.dispose();
            }
            window.removeEventListener('resize', this.onWindowResize);
        };
    },

    initThreeJS() {
        // Initialize scene
        threeInstances.scene = new THREE.Scene();
        
        // Initialize camera
        threeInstances.camera = new THREE.PerspectiveCamera(
            55,
            window.innerWidth / window.innerHeight,
            1,
            20000
        );
        threeInstances.camera.position.set(30, 30, 100);

        // Initialize renderer
        const canvas = this.$refs.canvas;
        threeInstances.renderer = new THREE.WebGLRenderer({
            canvas,
            antialias: true
        });
        threeInstances.renderer.setSize(window.innerWidth, window.innerHeight);
        threeInstances.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        // Initialize controls
        threeInstances.controls = new OrbitControls(threeInstances.camera, canvas);
        threeInstances.controls.maxPolarAngle = Math.PI * 0.495;
        threeInstances.controls.target.set(0, 10, 0);
        threeInstances.controls.minDistance = 40.0;
        threeInstances.controls.maxDistance = 200.0;
        threeInstances.controls.update();

        this.initWater();
        this.initSky();

        // Handle window resize
        window.addEventListener('resize', () => this.onWindowResize());
    },

    initWater() {
        const waterGeometry = new THREE.PlaneGeometry(10000, 10000);
        const waterNormals = new THREE.TextureLoader().load(
            '/textures/waternormals.jpg',
            function (texture) {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            }
        );

        threeInstances.water = new Water(waterGeometry, {
            textureWidth: 512,
            textureHeight: 512,
            waterNormals,
            sunDirection: new THREE.Vector3(),
            sunColor: 0xffffff,
            waterColor: 0x001e0f,
            distortionScale: 3.7,
            fog: threeInstances.scene.fog !== undefined
        });

        threeInstances.water.rotation.x = -Math.PI / 2;
        threeInstances.scene.add(threeInstances.water);
    },

    initSky() {
        threeInstances.sky = new Sky();
        threeInstances.sky.scale.setScalar(10000);
        threeInstances.scene.add(threeInstances.sky);

        threeInstances.sun = new THREE.Vector3();

        const uniforms = threeInstances.sky.material.uniforms;
        uniforms['turbidity'].value = 10;
        uniforms['rayleigh'].value = 2;
        uniforms['mieCoefficient'].value = 0.005;
        uniforms['mieDirectionalG'].value = 0.8;

        const phi = THREE.MathUtils.degToRad(90 - 2);
        const theta = THREE.MathUtils.degToRad(180);
        threeInstances.sun.setFromSphericalCoords(1, phi, theta);
        uniforms['sunPosition'].value.copy(threeInstances.sun);
        threeInstances.water.material.uniforms['sunDirection'].value.copy(threeInstances.sun).normalize();
    },

    animate() {
        this.animationFrameId = requestAnimationFrame(() => this.animate());
        this.render();
    },

    render() {
        if (threeInstances.water) {
            const time = performance.now() * 0.001;
            threeInstances.water.material.uniforms['time'].value = time;
        }
        if (threeInstances.renderer && threeInstances.scene && threeInstances.camera) {
            threeInstances.renderer.render(threeInstances.scene, threeInstances.camera);
        }
    },

    onWindowResize() {
        if (threeInstances.camera && threeInstances.renderer) {
            threeInstances.camera.aspect = window.innerWidth / window.innerHeight;
            threeInstances.camera.updateProjectionMatrix();
            threeInstances.renderer.setSize(window.innerWidth, window.innerHeight);
        }
    },

  setupEventListeners() {
      this.$refs.worldMap?.addEventListener('click', (e) => this.handleMapClick(e));
  },

  handleMapClick(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const width = event.target.width;
      const height = event.target.height;

      // Convert click coordinates to lat/lon
      const lon = (x / width) * 360 - 180;
      const lat = 90 - (y / height) * 180;

      this.locationInfo = `Selected location: ${lat.toFixed(2)}°N, ${lon.toFixed(2)}°E`;
      this.updateOceanConditions(lat, lon);
      this.showMapModal = false;
  },

  toggleMapModal() {
      this.showMapModal = !this.showMapModal;
  },

  toggleHelpModal() {
      this.showHelpModal = !this.showHelpModal;
  },

  async updateOceanConditions(lat, lon) {
      try {
          // Fetch ocean data from NOAA API
          const response = await fetch(`/api/ocean-conditions?lat=${lat}&lon=${lon}`);
          const data = await response.json();
          
          // Update water simulation parameters based on data
          // This is a placeholder - implement actual parameter updates
          this.water.material.uniforms['distortionScale'].value = data.waveHeight || 3.7;
      } catch (error) {
          console.error('Failed to update ocean conditions:', error);
      }
  }
}));
