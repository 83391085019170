import * as solanaWeb3 from '@solana/web3.js';

// Initialize Solana web3 if not already available
if (!window.solanaWeb3) {
  window.solanaWeb3 = solanaWeb3;
}

const CSEAS_ADDRESS = '92dLaNpWekXPkqiFXKmBgEGjfzZJ5bC79AKeLaqAjXUo';

const CSEAS_TOKEN = {
    name: "CurrentSeas",
    symbol: "CSEAS",
    emoji: "🌊",
    address: CSEAS_ADDRESS
};

const REALITY_TOKENS = {
    'OHRT': {
        name: "Ocean Heart",
        symbol: "OHRT",
        emoji: "💙",
        address: '', // TODO: Add token address when deployed
    },
    'DEEP': {
        name: "Deep Blue",
        symbol: "DEEP",
        emoji: "🌊",
        address: '', // TODO: Add token address when deployed
    },
    'LOVE': {
        name: "Sea Love",
        symbol: "LOVE",
        emoji: "❤️",
        address: '', // TODO: Add token address when deployed
    },
    'HONEY': {
        name: "Honey",
        symbol: "HONEY",
        emoji: "🍯",
        address: '', // TODO: Add token address when deployed
    }
};

const swapPage = () => {
    return {
        fromToken: REALITY_TOKENS.HONEY,
        toToken: CSEAS_TOKEN,
        fromAmount: '',
        toAmount: '',
        fromBalance: 0,
        toBalance: 0,
        showFromTokenSelect: false,
        showToTokenSelect: false,
        isSwapping: false,
        error: null,

        get availableFromTokens() {
            return [CSEAS_TOKEN, ...Object.values(REALITY_TOKENS)];
        },

        get availableToTokens() {
            return this.fromToken === CSEAS_TOKEN 
                ? [CSEAS_TOKEN]
                : Object.values(REALITY_TOKENS);
        },

        get canSwap() {
            return this.$store.wallet.isConnected && 
                   this.fromAmount && 
                   this.toAmount && 
                   !this.isSwapping &&
                   !this.error;
        },

        get swapButtonText() {
            if (!this.$store.wallet.isConnected) return 'Connect Wallet';
            if (this.isSwapping) return 'Swapping...';
            if (this.error) return this.error;
            if (!this.fromAmount || !this.toAmount) return 'Enter Amount';
            return 'Swap';
        },

        formatBalance(balance) {
            return balance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 6
            });
        },

        async init() {
            // Initialize animations safely
            this.initAnimations();
            
            this.parseUrlParams();
            if (this.$store.wallet.isConnected) {
                await this.updateBalances();
            }
            
            this.$watch('$store.wallet.isConnected', async (isConnected) => {
                if (isConnected) {
                    await this.updateBalances();
                }
            });
        },

        initAnimations() {
            // Safely initialize GSAP animations with null checks
            const textAnimation = document.querySelector('.text-6xl');
            if (textAnimation) {
                gsap.from(textAnimation, { 
                    opacity: 0,
                    y: 20,
                    duration: 0.5
                });
            }

            const gridItems = document.querySelectorAll('.grid-cols-1 > div:not(.card)');
            if (gridItems.length > 0) {
                gsap.from(gridItems, {
                    opacity: 0,
                    y: 20,
                    stagger: 0.1,
                    duration: 0.3
                });
            }
        },

        parseUrlParams() {
            const pathParts = window.location.pathname.split('/');
            if (pathParts.length >= 3 && pathParts[1] === 'swap') {
                const [fromSymbol, toSymbol] = pathParts[2].split('-');
                
                if (fromSymbol) {
                    if (fromSymbol === 'CSEAS') {
                        this.fromToken = CSEAS_TOKEN;
                    } else if (REALITY_TOKENS[fromSymbol]) {
                        this.fromToken = REALITY_TOKENS[fromSymbol];
                    }
                }

                if (toSymbol) {
                    if (toSymbol === 'CSEAS') {
                        this.toToken = CSEAS_TOKEN;
                    } else if (REALITY_TOKENS[toSymbol]) {
                        this.toToken = REALITY_TOKENS[toSymbol];
                    }
                }
            }

            const urlParams = new URLSearchParams(window.location.search);
            const amount = urlParams.get('amount');
            if (amount) {
                this.fromAmount = amount;
                this.handleFromAmountChange();
            }
        },

        updateUrl() {
            const newPath = `/swap/${this.fromToken.symbol}-${this.toToken.symbol}`;
            const params = new URLSearchParams();
            if (this.fromAmount) params.set('amount', this.fromAmount);
            
            const newUrl = `${newPath}?${params.toString()}`;
            window.history.replaceState({}, '', newUrl);
        },

        async updateBalances() {
            if (!this.$store.wallet.isConnected) return;
            
            try {
                const connection = new window.solanaWeb3.Connection(
                    window.solanaWeb3.clusterApiUrl('mainnet-beta')
                );
                const publicKey = new window.solanaWeb3.PublicKey(this.$store.wallet.address);
                const balance = await connection.getBalance(publicKey);
                this.fromBalance = balance / window.solanaWeb3.LAMPORTS_PER_SOL;
                this.toBalance = 1000; // TODO: Implement reality coin balance fetching
            } catch (error) {
                console.error('Error fetching balances:', error);
                this.showToast('Failed to fetch balances', 'error');
            }
        },

        setHalf() {
            this.fromAmount = (this.fromBalance / 2).toString();
            this.handleFromAmountChange();
        },

        setMax() {
            this.fromAmount = this.fromBalance.toString();
            this.handleFromAmountChange();
        },

        async handleFromAmountChange() {
            if (!this.fromAmount) {
                this.toAmount = '';
                return;
            }

            try {
                const quote = await this.getQuote('buy');
                this.toAmount = quote.expectedAmount;
                this.error = null;
            } catch (error) {
                console.error('Error getting quote:', error);
                this.error = error.message;
                this.toAmount = '';
            }

            this.updateUrl();
        },

        async getQuote(direction) {
            const amount = direction === 'buy' ? this.fromAmount : this.toAmount;
            const tokenSymbol = this.toToken.symbol;

            const response = await fetch('/api/swap-quote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tokenSymbol,
                    amount,
                    slippageBps: 100,
                    walletAddress: this.$store.wallet.address,
                    direction
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Failed to get quote');
            }

            return await response.json();
        },

        selectFromToken(token) {
            this.fromToken = token;
            if (token === this.toToken) {
                this.toToken = token === CSEAS_TOKEN ? REALITY_TOKENS.HONEY : CSEAS_TOKEN;
            }
            this.fromAmount = '';
            this.toAmount = '';
            this.updateUrl();
        },

        selectToToken(token) {
            this.toToken = token;
            this.fromAmount = '';
            this.toAmount = '';
            this.updateUrl();
        },

        swapDirection() {
            const temp = this.fromToken;
            this.fromToken = this.toToken;
            this.toToken = temp;
            
            const tempAmount = this.fromAmount;
            this.fromAmount = this.toAmount;
            this.toAmount = tempAmount;
            
            this.updateUrl();
        },

        async executeSwap() {
            if (!this.canSwap) return;

            try {
                this.isSwapping = true;
                this.error = null;

                const quote = await this.getQuote(this.fromToken === CSEAS_TOKEN ? 'buy' : 'sell');
                const transaction = await this.$store.wallet.createSwapTransaction(quote.instructions);
                const signature = await this.$store.wallet.sendTransaction(transaction);

                this.showToast('Swap successful! 🎉', 'success');
                await this.updateBalances();

                this.fromAmount = '';
                this.toAmount = '';
                
            } catch (error) {
                console.error('Swap error:', error);
                this.error = error.message;
                this.showToast('Swap failed: ' + error.message, 'error');
            } finally {
                this.isSwapping = false;
            }
        },

        showToast(message, type = 'info') {
            const toast = document.createElement('div');
            toast.className = `alert alert-${type} mb-2`;
            toast.innerHTML = `<div>${message}</div>`;
            
            const container = document.getElementById('toast-container');
            container.appendChild(toast);
            
            setTimeout(() => {
                toast.classList.add('fade-out');
                setTimeout(() => container.removeChild(toast), 300);
            }, 3000);
        },

        connectWallet() {
            this.$store.wallet.connect();
        }
    };
};

export default swapPage;
