import './types';

// Proper type declaration for Stripe
declare const Stripe: {
    (key: string): {
        elements: (options?: any) => {
            create: (type: string, options?: any) => any;
            getElement: (type: string) => any;
        };
        confirmCardPayment: (clientSecret: string, options: any) => Promise<any>;
    };
};

// Initialize Stripe
const stripe = Stripe('pk_live_515ac1MEXI0uHuSUFCcgBEESf1y3HGZ3CQJmQYZSMR9mJoo4RmGgvOK31Ayq0UWAQhwICPXQEVBAZgeumXhf3iMhA00Iilj72XX');

// Initialize Stripe Elements with appearance
const elements = stripe.elements({
    appearance: {
        theme: 'night',
        variables: {
            colorPrimary: '#3b82f6',
            colorBackground: 'rgba(255, 255, 255, 0.8)',
            colorText: '#1a1f36',
            colorDanger: '#df1b41',
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px'
        }
    }
});

// Create and mount the card element
const card = elements.create('card', {
    style: {
        base: {
            iconColor: '#1a1f36',
            color: '#1a1f36',
            fontWeight: '500',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#6b7280'
            },
            ':-webkit-autofill': {
                color: '#1a1f36'
            }
        },
        invalid: {
            iconColor: '#df1b41',
            color: '#df1b41'
        }
    }
});

// Payment handling for Stripe
export async function initStripePayment(formData: FormData) {
    const submitButton = document.querySelector('#payment-form button[type="submit"]') as HTMLButtonElement;
    const errorElement = document.getElementById('payment-error');
    const successElement = document.getElementById('payment-success');
    const loadingElement = document.getElementById('payment-loading');

    try {
        if (submitButton) {
            submitButton.disabled = true;
            submitButton.textContent = 'Processing...';
        }
        if (loadingElement) loadingElement.style.display = 'block';
        if (errorElement) errorElement.style.display = 'none';
        if (successElement) successElement.style.display = 'none';

        // Create payment intent
        const response = await fetch('/api/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                amount: 2500, // $25.00
                currency: 'usd'
            })
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to create payment intent');
        }

        const { clientSecret } = await response.json();
        
        // Confirm card payment
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement('card'),
                billing_details: {
                    name: formData.get('name') as string,
                    email: formData.get('email') as string,
                    address: {
                        line1: formData.get('address') as string,
                        city: formData.get('city') as string,
                        state: formData.get('state') as string,
                        postal_code: formData.get('zip') as string,
                        country: formData.get('country') as string
                    }
                }
            }
        });

        if (result.error) {
            throw new Error(result.error.message || 'Payment failed');
        }

        // Handle success
        if (successElement) {
            successElement.innerHTML = `
                <div class="text-green-500 font-bold mb-2">Payment Successful!</div>
                <div class="text-white">
                    <p>Order ID: ${result.paymentIntent.id}</p>
                    <p>Amount: $${(result.paymentIntent.amount / 100).toFixed(2)} USD</p>
                    <p>Your CSEAS pendant will be shipped to the provided address.</p>
                    <p>Check your email for order confirmation and tracking details.</p>
                </div>
            `;
            successElement.style.display = 'block';
        }

        // Clear form
        const form = document.getElementById('payment-form') as HTMLFormElement;
        if (form) form.reset();
        card.clear();

    } catch (error) {
        console.error('Payment error:', error);
        if (errorElement) {
            errorElement.textContent = error.message || 'Payment failed';
            errorElement.style.display = 'block';
        }
    } finally {
        if (submitButton) {
            submitButton.disabled = false;
            submitButton.textContent = 'Pay with Card';
        }
        if (loadingElement) loadingElement.style.display = 'none';
    }
}

// Crypto bridge integration
export async function initCryptoBridge() {
    // Custom crypto bridge implementation
    // This will be implemented when the bridge is built
    console.log('Crypto bridge payment initiated');
    return new Promise<void>((resolve) => {
        // Placeholder for future implementation
        resolve();
    });
}

// Phantom wallet integration
export async function connectPhantomWallet() {
    try {
        const provider = window?.phantom?.solana;
        
        if (!provider?.isPhantom) {
            window.open('https://phantom.app/', '_blank');
            return;
        }

        const connection = await provider.connect();
        const publicKey = connection.publicKey.toString();
        
        // Handle Solana payment flow
        console.log('Connected to Phantom wallet:', publicKey);

        // TODO: Implement Solana payment logic
        // 1. Create transaction
        // 2. Request signature
        // 3. Send transaction
        // 4. Confirm transaction

    } catch (error) {
        console.error('Phantom wallet connection failed:', error);
        throw error;
    }
}

// Mount Stripe card element and set up form handling
document.addEventListener('DOMContentLoaded', () => {
    const cardElement = document.getElementById('card-element');
    if (cardElement) {
        card.mount('#card-element');
        
        // Add card element error handling
        card.on('change', ({error}: {error?: {message: string}}) => {
            const displayError = document.getElementById('card-errors');
            if (displayError) {
                displayError.textContent = error ? error.message : '';
            }
        });
    }

    // Add payment form submit handler
    const form = document.getElementById('payment-form');
    if (form) {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            await initStripePayment(formData);
        });
    }
});
